<template>
  <div>
    <div class="bank_border" v-if="bankCardList.length">
      <el-descriptions
        class="descriptions_top"
        :column="4"
        v-for="item in bankCardList"
        :key="item.id"
      >
        <el-descriptions-item label="类别">
          <span v-if="item.cardType==='2'">主卡</span>
          <span v-else>附属卡</span>
        </el-descriptions-item>
        <el-descriptions-item label="开户行">
          {{ item.bankName || "--" }}
          <span v-if="item.cardType==='2'">(二类户)</span>
        </el-descriptions-item>
        <el-descriptions-item label="卡号">
          <div v-if="item.accountCode">
            <span
              v-if="!item.show && item.accountCode.length > 7"
              @click="item.show = true"
              style="color:cornflowerblue"
            >
              {{
              item.accountCode.substr(0, 3) + new Array(item.accountCode.length -
              7).join('*') + item.accountCode.substr(-4)
              }}
            </span>
            <span v-else @click="item.show = false" style="color:cornflowerblue">
              {{
              item.accountCode || "--"
              }}
            </span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="开户时间" v-if="item.cardType==='2'">{{ item.createTime || "--" }}</el-descriptions-item>
        <el-descriptions-item label="添加时间" v-else>{{ item.createTime || "--" }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="bank_border" v-if="mybankList.length">
      <el-descriptions
        class="descriptions_top"
        :column="4"
        v-for="item in mybankList"
        :key="item.id"
      >
        <el-descriptions-item label="类别">
          <span v-if="item.cardType==='2'">主卡</span>
          <span v-else>附属卡</span>
        </el-descriptions-item>
        <el-descriptions-item label="开户行">{{ item.bankName || "--" }}</el-descriptions-item>
        <el-descriptions-item label="卡号">
          <div v-if="item.accountCode">
            <span
              v-if="!item.show && item.accountCode.length > 7"
              @click="item.show = true"
              style="color:cornflowerblue"
            >
              {{
              item.accountCode.substr(0, 3) + new Array(item.accountCode.length -
              7).join('*') + item.accountCode.substr(-4)
              }}
            </span>
            <span v-else @click="item.show = false" style="color:cornflowerblue">
              {{
              item.accountCode || "--"
              }}
            </span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="开户时间" v-if="item.cardType==='2'">{{ item.createTime || "--" }}</el-descriptions-item>
        <el-descriptions-item label="添加时间" v-else>{{ item.createTime || "--" }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <el-empty v-else :image-size="200" :description="title"></el-empty>
    <!-- <el-card class="box-card"></el-card> -->
    <!-- <Divider :title="'工资卡信息'" :tag="`工资卡为默认卡，用于资金发放打款，不允许修改`"></Divider>
    <el-descriptions class="margin-top" style="margin-top: 20px" :column="3">
      <el-descriptions-item label="开户行">{{ erleihuDetail.bankName || "--" }}</el-descriptions-item>
      <el-descriptions-item label="卡号">
        <div v-if="erleihuDetail.accountCode">
          <span
            v-if="!erleihuShow && erleihuDetail.accountCode.length > 7"
            @click="erleihuShow = true"
            style="color:cornflowerblue"
          >
            {{
            erleihuDetail.accountCode.substr(0, 3) + new Array(erleihuDetail.accountCode.length -
            7).join('*') + erleihuDetail.accountCode.substr(-4)
            }}
          </span>
          <span v-else @click="erleihuShow = false" style="color:cornflowerblue">
            {{
            erleihuDetail.accountCode || "--"
            }}
          </span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="开户时间">{{ erleihuDetail.createTime || "--" }}</el-descriptions-item>
    </el-descriptions>
    <Divider :title="'提现卡信息'" :tag="`提现卡为开户时绑定的银行卡，工资卡进行提现转账时，会将资金转入到提现卡账户中`"></Divider>

    <el-descriptions class="margin-top" style="margin-top: 20px" :column="3">
      <el-descriptions-item label="开户行">{{ withdrawalList.bankName || "--" }}</el-descriptions-item>
      <el-descriptions-item label="卡号">
        <div v-if="withdrawalList.accountCode">
          <span
            v-if="!withdrawalShow && withdrawalList.accountCode.length > 7"
            @click="withdrawalShow = true"
            style="color:cornflowerblue"
          >
            {{
            withdrawalList.accountCode.substr(0, 3) + new Array(withdrawalList.accountCode.length -
            7).join('*') + withdrawalList.accountCode.substr(-4)
            }}
          </span>
          <span v-else @click="withdrawalShow = false" style="color:cornflowerblue">
            {{
            withdrawalList.accountCode || "--"
            }}
          </span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="开户时间">{{ withdrawalList.createTime || "--" }}</el-descriptions-item>
    </el-descriptions>
    <Divider :title="'绑定卡信息'"></Divider>
    <div v-if="bankCardList.length">
      <el-descriptions
        class="margin-top"
        style="margin-top: 20px"
        :column="3"
        v-for="item in bankCardList"
        :key="item.id"
      >
        <el-descriptions-item label="开户行">{{ item.bankName || "--" }}</el-descriptions-item>
        <el-descriptions-item label="卡号">
          <div v-if="item.accountCode">
            <span
              v-if="!item.show && item.accountCode.length > 7"
              @click="item.show = true"
              style="color:cornflowerblue"
            >
              {{
              item.accountCode.substr(0, 3) + new Array(item.accountCode.length -
              7).join('*') + item.accountCode.substr(-4)
              }}
            </span>
            <span v-else @click="item.show = false" style="color:cornflowerblue">
              {{
              item.accountCode || "--"
              }}
            </span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="开户时间">{{ item.createTime || "--" }}</el-descriptions-item>
      </el-descriptions>
    </div>

    -->
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const bankCardList = api()('bank.card.list.json');
export const bankCardSetDefault = api()('bank.card.setDefault.json');
export const bankCardDelete = api()('bank.card.delete.json');

export default {
  props: {
    userCode: String,
    bankFormVisible: Boolean,
  },
  components: {
    // Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      bankCardList: [],
      mybankList: [],
      erleihuDetail: {},
      erleihuShow: false,
      withdrawalShow: false,
      withdrawalList: {},
      title: '暂无绑卡信息'
    };
  },
  async created () {
    await this.getBankCardList();
  },
  methods: {
    async getBankCardList () {
      const res = await bankCardList({ subject: this.userCode });
      if (res.list.length > 0) {
        this.bankCardList = res.list.map((it) => ({ ...it, show: false })).filter(i => i.bankType === 'PINGAN')
        this.mybankList = res.list.map((it) => ({ ...it, show: false })).filter(i => i.bankType === 'mybank2')
      }
      else {
        this.bankCardList = []
      }

      console.log(this.bankCardList, this.mybankList);
    },
    async setDefault (row) {
      await bankCardSetDefault({
        userCode: row.subject,
        code: row.code,
      });
      this.$message.success(
        `银行卡 ${row.bankName} - ${row.accountCode} 已设为默认卡`,
      );
      this.getBankCardList();
    },
    overflow () {
      this.$emit('IsbankFormVisible', true);
    },
    delBank (row) {
      this.$confirm('此操作将永久删除该银行卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.del(row);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    async del (row) {
      await bankCardDelete({
        subject: row.subject,
        accountCode: row.accountCode,
      });
      this.$message.success(
        `银行卡 ${row.bankName} - ${row.accountCode} 已删除`,
      );
      this.getBankCardList();
    },
  },
};
</script>
<style lang="scss">
.bank_border {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
.descriptions_top {
  margin-top: 20px;
  border-bottom: 2px dashed #999;
}
.descriptions_top:last-child {
  border: none;
}
</style>
